/**
 * @package     Joomla.Site
 * @subpackage  Templates.redWEB
 *
 * @copyright   Copyright (C) 2020 redWEB. All rights reserved.
 * @license     GNU General Public License version 2 or later; see LICENSE.txt
 */

import(/* webpackPrefetch: true */ '../utils/header.js');

//Load fontface
import(/* webpackChunkName: "fontfaceobserver" */ 'fontfaceobserver').then((module) => {
  const FontFaceObserver = module.default;

  const fontRoboto = new FontFaceObserver('Roboto-Regular');
  const fontRobotoMedium = new FontFaceObserver('Roboto-Medium');
  const fontRobotoBold = new FontFaceObserver('Roboto-Bold');
  const fontQuicksandBold = new FontFaceObserver('Quicksand-Bold');

  Promise.all([
    fontRoboto.load(),
    fontRobotoMedium.load(),
    fontRobotoBold.load(),
    fontQuicksandBold.load(),
  ]).then(function () {
    document.documentElement.style.setProperty(
      '--bs-font-sans-serif',
      'Roboto-Regular, sans-serif'
    );
    document.documentElement.style.setProperty(
      '--bs-font-sans-serif-medium',
      'Roboto-Medium, sans-serif'
    );
    document.documentElement.style.setProperty(
      '--bs-font-sans-serif-bold',
      'Roboto-Bold, sans-serif'
    );
    document.documentElement.style.setProperty(
      '--bs-font-sans-serif-quicksand-bold',
      'Quicksand-Bold, sans-serif'
    );
  });
});

//loader
window.addEventListener('DOMContentLoaded', function (event) {
  document.querySelector('.loading-first').classList.add('hided');
});

if (document.body.contains(document.querySelector('.faq_list'))) {
  var clickItem = document.querySelectorAll('.faq_readmore');
  var itemShow = document.querySelectorAll('.faq_block');
  for (var i = 0; i < itemShow.length; i++) {
    clickItem[i].addEventListener('click', function (event) {
      event.preventDefault();
      this.classList.toggle('open');
      this.parentElement.previousSibling.classList.toggle('open');
    });
  }
}
if (document.body.contains(document.querySelector('.quantity_field'))) {
  jQuery(document).ready(function ($) {
    $('.quatity_input').each(function () {
      var block = $(this).parent();
      block.find('.quantity-add').click(function () {
        var currentVal = parseInt(block.find('.quatity_input').val());
        if (currentVal != NaN) {
          block.find('.quatity_input').val(currentVal + 1);
        }
      });

      block.find('.quantity-remove').click(function () {
        var currentVal = parseInt(block.find('.quatity_input').val());
        if (currentVal != NaN) {
          block.find('.quatity_input').val(currentVal - 1);
        }
      });
    });
  });
}
